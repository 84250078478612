import React from "react"

import { DriveLayout } from "components/layout-custom"
import TeamRota from "components/rota"

import { Audience } from "types/audience"

const Page = () => {
  return (
    <DriveLayout title="Team Rota" padded={false}>
      <TeamRota audience={Audience.driver} />
    </DriveLayout>
  )
}

export default Page
